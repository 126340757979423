export default {
  methods: {
    translateBody(body) {
      switch (body) {
        case "minivan":
          return "Минивэн";
        case "fura":
          return "Фура";
        case "hatchback":
          return "Хэтчбек";
        case "sprinter":
          return "Спринтер";
        case "trailer":
          return "Прицеп";
        default:
          if (body)
            return (body[0].toUpperCase() + body.slice(1)).replace(/_/g, " ");
      }
    },
  },
};
