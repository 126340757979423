<template>
  <div>
    <b-spinner v-if="spinnerShow" class="spinner" label="Large Spinner" />
    <p v-if="transportList && transportList.meta" class="pogination__text">
      {{ transportList.meta.from }}-{{ transportList.meta.to }} из
      {{ transportList.meta.total }}
    </p>
    <b-pagination
      v-model="currentPage"
      v-if="transportList && transportList.meta"
      :total-rows="transportList.meta.total"
      :per-page="perPage"
      aria-controls="my-table"
      :hide-goto-end-buttons="true"
      :limit="1"
      class="pogination"
      @input="changePage()"
    >
      <template #prev-text>
        <img
          class="pagination__btn-icon pagination__btn-icon_prev"
          src="@/assets/png/database-icon-active.png"
          alt=""
        />
      </template>
      <template #next-text>
        <img class="pagination__btn-icon" src="@/assets/png/database-icon-active.png" alt="" />
      </template>
    </b-pagination>
    <div class="table">
      <b-table
        v-if="transportList"
        id="my-table"
        class="table__box"
        :borderless="true"
        :items="transportList.data"
        :fields="fields"
        small
      >
        <template #cell(ref)="data">
          <!-- TODO condition -->
          <img
            src="/success.svg"
            class="success-img"
            v-if="
              (data.item.characteristics &&
                data.item.characteristics.length &&
                data.item.characteristics[0].temp) ||
              (data.item.trailer &&
                data.item.trailer.characteristics &&
                data.item.trailer.characteristics.length &&
                data.item.trailer.characteristics[0].temp)
            "
          />
          <img src="/error.svg" class="error-img" v-else />
        </template>
        <!-- <template #cell(weight)="data">
          <div v-if="data.item.characteristics && data.item.characteristics.length && !data.item.trailer">{{ weightWithSpaces(data.item.characteristics[0].capacity) }}</div>
          <div v-else-if="data.item.trailer && data.item.trailer.characteristics.length">{{ data.item.characteristics[0]?.capacity + data.item.trailer?.characteristics[0]?.capacity }}</div>
        </template> -->
        <template #cell(sensors)="data">
          <div class="row m-2 justify-content-around">
            <div>
              <svg
                width="25"
                height="25"
                viewBox="0 0 8 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.99984 4.3335V8.3335"
                  :stroke="data.item.temp > 0 && data.item.temp !== null ? 'green' : '#ACACAC'"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M4.94265 8.72402C5.46335 9.24472 5.46335 10.0889 4.94265 10.6096C4.42195 11.1303 3.57773 11.1303 3.05703 10.6096C2.53633 10.0889 2.53633 9.24472 3.05703 8.72402C3.57773 8.20332 4.42195 8.20332 4.94265 8.72402"
                  :stroke="data.item.temp > 0 && data.item.temp !== null  ? 'green' : '#ACACAC'"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.99984 7.01533V3C1.99984 1.89533 2.89517 1 3.99984 1C5.1045 1 5.99984 1.89533 5.99984 3V7.01533C6.80517 7.624 7.33317 8.58 7.33317 9.66667C7.33317 11.5073 5.8405 13 3.99984 13C2.15917 13 0.666504 11.5073 0.666504 9.66667C0.666504 8.58 1.1945 7.62333 1.99984 7.01533Z"
                  :stroke="data.item.temp > 0 && data.item.temp !== null ? 'green' : '#ACACAC'"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div>
              <svg
                width="25"
                height="25"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.3335 8.00016C13.3335 10.9457 10.9457 13.3335 8.00016 13.3335M13.3335 8.00016C13.3335 5.05464 10.9457 2.66683 8.00016 2.66683M13.3335 8.00016H14.6668M8.00016 13.3335C5.05464 13.3335 2.66683 10.9457 2.66683 8.00016M8.00016 13.3335V14.6668M2.66683 8.00016C2.66683 5.05464 5.05464 2.66683 8.00016 2.66683M2.66683 8.00016H1.3335M8.00016 2.66683V1.3335M10.0002 8.00016C10.0002 9.10473 9.10473 10.0002 8.00016 10.0002C6.89559 10.0002 6.00016 9.10473 6.00016 8.00016C6.00016 6.89559 6.89559 6.00016 8.00016 6.00016C9.10473 6.00016 10.0002 6.89559 10.0002 8.00016Z"
                  :stroke="
                    data.item.tracked
                      ? 'green'
                      : '#ACACAC'
                  "
                  stroke-width="1.33333"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div>
              <svg
                width="25"
                height="25"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4.3335 4.6665H8.3335V7.33317H4.3335V4.6665Z"
                  :stroke="data.item.fuel ? 'green' : '#ACACAC'"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M5 9.99984H7.66667"
                  :stroke="data.item.fuel ? 'green' : '#ACACAC'"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M2.3335 14V3.33333C2.3335 2.59667 2.93016 2 3.66683 2H9.00016C9.73683 2 10.3335 2.59667 10.3335 3.33333V14"
                  :stroke="data.item.fuel ? 'green' : '#ACACAC'"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M13.0002 3.3335L13.9428 4.27616C14.1928 4.52616 14.3335 4.8655 14.3335 5.21883V11.6668C14.3335 12.2188 13.8855 12.6668 13.3335 12.6668V12.6668C12.7815 12.6668 12.3335 12.2188 12.3335 11.6668V10.6668C12.3335 10.2988 12.0348 10.0002 11.6668 10.0002H10.3335"
                  :stroke="data.item.fuel ? 'green' : '#ACACAC'"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14.3332 8.00016H13.0885C12.6878 8.00016 12.3772 7.64883 12.4272 7.25083L12.5938 5.9175C12.6352 5.58416 12.9185 5.3335 13.2552 5.3335H14.3332"
                  :stroke="data.item.fuel ? 'green' : '#ACACAC'"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M1.6665 13.9998H10.9998"
                  :stroke="data.item.fuel ? 'green' : '#ACACAC'"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
          <!-- <div v-else-if="data.item.trailer && data.item.trailer.characteristics.length">{{ data.item.characteristics[0]?.capacity + data.item.trailer?.characteristics[0]?.capacity }}</div> -->
        </template>
        <template #cell(city)="data">
          <span v-show="data.item.city_id !== null" class="city">{{
            data.item.city_id?.name
          }}</span>
        </template>
        <template #cell(number)="data">
          <span style="font-weight: 600">{{ plateNumberWithSpaces(data.item.number) }}</span>
        </template>
        <template #cell(auto)="data">
          <div class="table-name">
            <button
              class="table-name__btn"
              style="font-weight: 600"
              @click="$router.push(`/database/info-transport/${data.item.id}`)"
            >
              {{ data.item.model ? data.item.mark_model : "" }}
            </button>
          </div>
        </template>
        <template #cell(updated)="data">
          {{ data.item.car_date }}
        </template>
        <template #cell(body)="data">
          <span class="table-cell-body">
            {{ translateBody(data.item.body) }}
          </span>
        </template>
        <template #cell(status)="data">
          <span :class="statuses[data.item.status]">
            {{ status(data.item.status) }}
          </span>
          <span v-if="data.item.status === 'on_rent'">до {{ data.item.rent_to_date }}</span>
        </template>
        <template #cell(action)="data">
          <div class="">
            <a class="table-btn">
              <b-icon
                icon="pencil-square"
                aria-hidden="true"
                class="mr-2"
                variant="secondary"
                @click="$router.push(`/add/new-transport?id=${data.item.id}`)"
              />
            </a>
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import transportBodyMixin from "@/mixins/transportBody";

export default {
  mixins: [transportBodyMixin],
  data() {
    return {
      swowItem: null,
      perPage: 10,
      currentPage: 1,
      fields: [
        {
          key: "auto",
          label: "Авто",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "number",
          label: "Номер",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "registration",
          label: "VIN код",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "body",
          label: "Вид",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "ref",
          label: "Реф.",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        // {
        //   key: "weight",
        //   label: "Грузоподъемность (кг)",
        //   sortable: true,
        //   isRowHeader: true,
        //   class: "tb-all",
        // },
        {
          key: "sensors",
          label: "Темп датчик, GPS, Топл датчик,",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "city",
          label: "Город",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "status",
          label: "Статус",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "action",
          label: "",
          sortable: false,
          isRowHeader: true,
          class: "tb-all tb-xs",
        },
      ],
      statuses: {
        free: "table-btn__status",
        onway: "table-btn__status-active table-btn__status",
        on_rent: "table-btn__status-active table-btn__status",
        busy: "table-btn__status-busy",
        sold: "table-btn__status-pending",
        repair: "table-btn__status-busy",
      },
      spinnerShow: false,
    };
  },
  computed: {
    ...mapState(["transportList", "textFilter", "statusFilter", "addressFilter", "typeFilter"]),
    filter() {
      return this.transportList.filter((e) => e.id?.toString().indexOf(this.textFilter || "") > -1);
    },
  },
  async created() {
    this.spinnerShow  = true
    if (this.$route.query.page != undefined && this.$route.query.page != 1) {
      this.currentPage = this.$route.query.page;
      this.spinnerShow  = false
    } else {
      await this.search();
    }

  },
  methods: {
    deleteHandler(city_id) {
      let ask = confirm("Are you sure you want to delete?");
      if (ask) {
        this.$api
          .post(`/web/delete-transport?transport_id=${city_id}`, {
            city_id,
            _method: "DELETE",
          })
          .then(() => {
            this.$toast.success("Успешно!");
            this.$store.dispatch("getCitiesProducts");
            this.$store.dispatch("getTransportList");
          })
          .catch((e) => {
            this.$toast.error(e.response.data);
          });
      }
    },
    status(value) {
      if (value === "free") {
        return "Свободен";
      } else if (value === "busy") {
        return "Занят";
      } else if (value === "sold") {
        return "Продана";
      } else if (value === "on_rent") {
        return "Аренда";
      } else if (value === "repair") {
        return "Ремонт";
      } else if (value === "onway") {
        return "В пути";
      }
    },
    changePage() {
      const searchURL = new URL(window.location);
      searchURL.searchParams.set("page", this.currentPage);
      window.history.pushState({}, "", searchURL);
    },
    weightWithSpaces(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    plateNumberWithSpaces(x) {
      if (x !== null) {
        return x.toString().replace(/[^0-9](?=[0-9])|[0-9](?=[^0-9])/g, "$& ");
      }
      return;
    },
    async search() {
      await this.$store.dispatch("getTransportList", {
        page: this.currentPage,
        status: this.statusFilter,
        city_id: this.addressFilter,
        type_filter: this.typeFilter,
        search: this.textFilter,
        type: "get_with_paginate",
      });
      this.spinnerShow  = false
    },
  },
  watch: {
    async currentPage() {
      this.spinnerShow  = true
      await this.search();
    },
    async statusFilter() {
      this.spinnerShow  = true
      this.currentPage = 1;
      await this.search();
    },
    async textFilter() {
      this.spinnerShow  = true
      this.currentPage = 1;
      await this.search();
    },
    async addressFilter() {
      this.spinnerShow  = true
      this.currentPage = 1;
      await this.search();
    },
    async typeFilter() {
      this.spinnerShow  = true
      this.currentPage = 1;
      await this.search();
    },
  },
};
</script>

<style>
.table {
  width: 100%;
}
.table-name {
  display: flex;
  align-items: center;
  justify-content: center;
}
.table-name__btn {
  background: transparent;
}
.table-name__btn:hover {
  color: #1bb55c;
}
.pogination,
.pogination__text {
  position: absolute;
  top: -164px;
  right: -3px;
}
.pogination__text {
  right: 128px;
  top: -154px;
}
.page-item:nth-child(2) {
  display: none;
}
.page-item {
  margin-right: 18px;
  border: 1px solid #e0e9fa;
  border-radius: 8px;
}
.sub-header__text {
  margin: 0 20px 0 26px;
  color: #707070;
}
.pagination__btn-icon_prev {
  transform: rotate(180deg);
}
.city {
  background: #f2f2f8;
  border-radius: 5px;
  padding: 8px 10px;
  font-weight: 500;
}
.table-btn__status-pending {
  display: block;
  padding: 8px 10px;
  height: auto;
  border-radius: 5px;
  background: #fff4d2;
  color: #ff9e0d;
}
.table-btn__status-busy {
  display: block;
  padding: 8px 10px;
  height: auto;
  border-radius: 5px;
  background: #fbd2ce;
  color: #e74c3c;
}
.success-img {
  background: #dcffe4;
  padding: 5px;
  border-radius: 5px;
}
.error-img {
  background: #fbd2ce;
  padding: 5px;
  border-radius: 5px;
}
</style>
